export const experienceFilter = [
  "6 months-1 year",
  "1-3 years",
  "4-7 years",
  "8-10 years",
  "11-15 years",
  "16+ years",
];

export const proficienyLevelList = [
  { value: "Beginner", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
  { value: "Not Applicable", label: "Not Applicable" },
];

export const experienceList = [
  {
    label: "6 months-1 year",
    value: 1,
  },
  {
    label: "1-3 years",
    value: 2,
  },
  {
    label: "4-7 years",
    value: 3,
  },
  {
    label: "8-10 years",
    value: 4,
  },
  {
    label: "11-15 years",
    value: 5,
  },
  {
    label: "16+ years",
    value: 6,
  },
];

export const skills = [
  {
    label: "Analytics",
    value: "Analytics",
  },
  {
    label: "Application Integration",
    value: "Application Integration",
  },
  {
    label: "AR & VR",
    value: "AR & VR",
  },
  {
    label: "AWS Cost Management",
    value: "AWS Cost Management",
  },
  {
    label: "Blockchain",
    value: "Blockchain",
  },
  {
    label: "Business Applications",
    value: "Business Applications",
  },
  {
    label: "Compute",
    value: "Compute",
  },
  {
    label: "Containers",
    value: "Containers",
  },
  {
    label: "Customer Engagement",
    value: "Customer Engagement",
  },
  {
    label: "Database",
    value: "Database",
  },
  {
    label: "Developer Tools",
    value: "Developer Tools",
  },
  {
    label: "End User Computing",
    value: "End User Computing",
  },
  {
    label: "Game Tech",
    value: "Game Tech",
  },
  {
    label: "Internet of Things",
    value: "Internet of Things",
  },
  {
    label: "Management & Governance",
    value: "Management & Governance",
  },
  {
    label: "Media Services",
    value: "Media Services",
  },
  {
    label: "Migration & Transfer",
    value: "Migration & Transfer",
  },
  {
    label: "Networking & Content Delivery",
    value: "Networking & Content Delivery",
  },
  {
    label: "Robotics",
    value: "Robotics",
  },
  {
    label: "Satellite",
    value: "Satellite",
  },
  {
    label: "Security, Identity, & Compliance",
    value: "Security, Identity, & Compliance",
  },
  {
    label: "Storage",
    value: "Storage",
  },
  {
    label: "Quantum Technologies",
    value: "Quantum Technologies",
  },
  {
    label: "Serverless",
    value: "Serverless",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const industeries = [
  { label: "Accounting", value: "Accounting" },
  { label: "Airlines/Aviation", value: "Airlines/Aviation" },
  {
    label: "Alternative Dispute Resolution",
    value: "Alternative Dispute Resolution",
  },
  { label: "Alternative Medicine", value: "Alternative Medicine" },
  { label: "Animation", value: "Animation" },
  { label: "Apparel & Fashion", value: "Apparel & Fashion" },
  {
    label: "Architecture & Planning",
    value: "Architecture & Planning",
  },
  { label: "Arts & Crafts", value: "Arts & Crafts" },
  { label: "Automotive", value: "Automotive" },
  { label: "Aviation and Aerospace", value: "Aviation and Aerospace" },
  { label: "Banking", value: "Banking" },
  { label: "Biotechnology", value: "Biotechnology" },
  { label: "Broadcast Media", value: "Broadcast Media" },
  { label: "Building Materials", value: "Building Materials" },
  {
    label: "Business Supplies & Equipment",
    value: "Business Supplies & Equipment",
  },
  { label: "Capital Markets", value: "Capital Markets" },
  { label: "Chemicals", value: "Chemicals" },
  {
    label: "Civic and Social Organization",
    value: "Civic and Social Organization",
  },
  { label: "Civil Engineering", value: "Civil Engineering" },
  { label: "Commercial Real Estate", value: "Commercial Real Estate" },
  {
    label: "Computer & Network Security",
    value: "Computer & Network Security",
  },
  { label: "Computer Games", value: "Computer Games" },
  { label: "Computer Hardware", value: "Computer Hardware" },
  { label: "Computer Networking", value: "Computer Networking" },
  { label: "Computer Software", value: "Computer Software" },
  { label: "Construction", value: "Construction" },
  { label: "Consumer Elecronics", value: "Consumer Elecronics" },
  { label: "Consumer Goods", value: "Consumer Goods" },
  { label: "Consumer Services", value: "Consumer Services" },
  { label: "Cosmetics", value: "Cosmetics" },
  { label: "Dairy", value: "Dairy" },
  { label: "Defense & Space", value: "Defense & Space" },
  { label: "Design", value: "Design" },
  { label: "E-learning", value: "E-learning" },
  { label: "Education Management", value: "Education Management" },
  {
    label: "Electrical & Electronic Manufacturing",
    value: "Electrical & Electronic Manufacturing",
  },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Enviromental Services", value: "Enviromental Services" },
  { label: "Events Services", value: "Events Services" },
  { label: "Executive Office", value: "Executive Office" },
  { label: "Facilities Services", value: "Facilities Services" },
  { label: "Farming", value: "Farming" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Fine Art", value: "Fine Art" },
  { label: "Fishery", value: "Fishery" },
  { label: "Food & Beverages", value: "Food & Beverages" },
  { label: "Food Production", value: "Food Production" },
  { label: "Fund-Raising", value: "Fund-Raising" },
  { label: "Furniture", value: "Furniture" },
  { label: "Gambling & Casinos", value: "Gambling & Casinos" },
  {
    label: "Glass, Ceramics & Concrete",
    value: "Glass, Ceramics & Concrete",
  },
  {
    label: "Government Administration",
    value: "Government Administration",
  },
  { label: "Government Relations", value: "Government Relations" },
  { label: "Graphic Design", value: "Graphic Design" },
  {
    label: "Health, Welness & Fitness",
    value: "Health, Welness & Fitness",
  },
  { label: "Higher Education", value: "Higher Education" },
  { label: "Hospital & Health Care", value: "Hospital & Health Care" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Human Resources", value: "Human Resources" },
  { label: "Import and Export", value: "Import and Export" },
  {
    label: "Individual & Family Services",
    value: "Individual & Family Services",
  },
  { label: "Industrial Automation", value: "Industrial Automation" },
  { label: "Information Services", value: "Information Services" },
  {
    label: "Information Technology and Services",
    value: "Information Technology and Services",
  },
  { label: "Insurance", value: "Insurance" },
  { label: "International Affairs", value: "International Affairs" },
  {
    label: "International Trade and Development",
    value: "International Trade and Development",
  },
  { label: "Internet", value: "Internet" },
  { label: "Investmend Management", value: "Investmend Management" },
  { label: "Investment Banking", value: "Investment Banking" },
  { label: "Judiciary", value: "Judiciary" },
  { label: "Law Enforcement", value: "Law Enforcement" },
  { label: "Law Practice", value: "Law Practice" },
  { label: "Legal Services", value: "Legal Services" },
  { label: "Legislative Office", value: "Legislative Office" },
  {
    label: "Leisure, Travel & Turism",
    value: "Leisure, Travel & Turism",
  },
  { label: "Libraries", value: "Libraries" },
  {
    label: "Logistics & Supply Chain",
    value: "Logistics & Supply Chain",
  },
  { label: "Luxury Goods & Jewelry", value: "Luxury Goods & Jewelry" },
  { label: "Machinery", value: "Machinery" },
  { label: "Management Consulting", value: "Management Consulting" },
  { label: "Maritime", value: "Maritime" },
  { label: "Market Research", value: "Market Research" },
  {
    label: "Marketing & Advertising",
    value: "Marketing & Advertising",
  },
  {
    label: "Mechanical or Industrial Engineering",
    value: "Mechanical or Industrial Engineering",
  },
  { label: "Media Production", value: "Media Production" },
  { label: "Medical Device", value: "Medical Device" },
  { label: "Medical Practice", value: "Medical Practice" },
  { label: "Mental Health Care", value: "Mental Health Care" },
  { label: "Military", value: "Military" },
  { label: "Mining & Metals", value: "Mining & Metals" },
  { label: "Motion Pictures & Film", value: "Motion Pictures & Film" },
  {
    label: "Museums and Institutions",
    value: "Museums and Institutions",
  },
  { label: "Music", value: "Music" },
  { label: "Nanotechnology", value: "Nanotechnology" },
  { label: "Newspapers", value: "Newspapers" },
  {
    label: "Non-profit Organization Management",
    value: "Non-profit Organization Management",
  },
  { label: "Oil & Energy", value: "Oil & Energy" },
  { label: "Online Media", value: "Online Media" },
  { label: "Outsourcing/Offshoring", value: "Outsourcing/Offshoring" },
  {
    label: "Package/Freight Delivery",
    value: "Package/Freight Delivery",
  },
  {
    label: "Packaging and Containers",
    value: "Packaging and Containers",
  },
  {
    label: "Paper & Forest Products",
    value: "Paper & Forest Products",
  },
  { label: "Performing Arts", value: "Performing Arts" },
  { label: "Pharmaceuticals", value: "Pharmaceuticals" },
  { label: "Philanthropy", value: "Philanthropy" },
  { label: "Photography", value: "Photography" },
  { label: "Plastics", value: "Plastics" },
  { label: "Political Organization", value: "Political Organization" },
  {
    label: "Primary/Secondary Education",
    value: "Primary/Secondary Education",
  },
  { label: "Printing", value: "Printing" },
  {
    label: "Professional Training & Coaching",
    value: "Professional Training & Coaching",
  },
  { label: "Program Development", value: "Program Development" },
  { label: "Public Policy", value: "Public Policy" },
  {
    label: "Public Relations and Communications",
    value: "Public Relations and Communications",
  },
  { label: "Public Safety", value: "Public Safety" },
  { label: "Publishing", value: "Publishing" },
  { label: "Railroad Manufacture", value: "Railroad Manufacture" },
  { label: "Ranching", value: "Ranching" },
  { label: "Real Estate", value: "Real Estate" },
  {
    label: "Recreational Facilities & Services",
    value: "Recreational Facilities & Services",
  },
  { label: "Religious Institutions", value: "Religious Institutions" },
  {
    label: "Renewables & Environment",
    value: "Renewables & Environment",
  },
  { label: "Research", value: "Research" },
  { label: "Restaurants", value: "Restaurants" },
  { label: "Retail", value: "Retail" },
  {
    label: "Security & Investigations",
    value: "Security & Investigations",
  },
  { label: "Semiconductors", value: "Semiconductors" },
  { label: "Shipbuilding", value: "Shipbuilding" },
  { label: "Sporting Goods", value: "Sporting Goods" },
  { label: "Sporting Goods", value: "Sporting Goods" },
  { label: "Staffing & Recruiting", value: "Staffing & Recruiting" },
  { label: "Supermarkets", value: "Supermarkets" },
  { label: "Telecommunications", value: "Telecommunications" },
  { label: "Textiles", value: "Textiles" },
  { label: "Think Thanks", value: "Think Thanks" },
  { label: "Tobacco", value: "Tobacco" },
  {
    label: "Translation & Localisation",
    value: "Translation & Localisation",
  },
  {
    label: "Transportation/Trucking/Railroad",
    value: "Transportation/Trucking/Railroad",
  },
  { label: "Utilities", value: "Utilities" },
  {
    label: "Venture Capital & Private Equity",
    value: "Venture Capital & Private Equity",
  },
  { label: "Veterinary", value: "Veterinary" },
  { label: "Warehousing", value: "Warehousing" },
  { label: "Wholesale", value: "Wholesale" },
  { label: "Wine & Spirits", value: "Wine & Spirits" },
  { label: "Wireless", value: "Wireless" },
  { label: "Writing & Editing", value: "Writing & Editing" },
];
